import React, {Suspense} from "react";
import {useSelector} from 'react-redux';
import {useState} from 'react';

//components
import CollapseTab from '../../components/Tabs/CollapseTab';
import Loading from "../../components/Loading/Loading";



export default () => {
    const DocumentsContainer = React.lazy(() => import('./components/DocumentsContainer'));
    const [show, setShow] = useState(false);
    let boxData = useSelector(state => state.app.data);
    boxData = boxData && boxData.respaldos;

    return (
        <div onClick={() => setShow(!show)}  className="DocumentsSection" id="documents">
            {
                boxData && boxData.header && boxData.body && boxData.footer &&
                <CollapseTab
                    sectionName="documents"
                    carouselLength={boxData.body && boxData.body.data && Array.isArray(boxData.body.data) ? boxData.body.data.length : 0}
                    nav={<div
                        className="font-roboto font-bold font-14 txt-blue line-1-2">{boxData.header.title}</div>}>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Suspense fallback={<Loading size='medium'/>}>
                            {show && <DocumentsContainer boxData={boxData}/>}
                        </Suspense>
                    </div>
                </CollapseTab>
            }
        </div>
    )
}
