import store from '../index';
import constants from '../constants';
import axios from 'axios';
import {getReportCode} from '../../helpers/report';

const envs = require('../../config.json');
const dispatch = store.dispatch;

export function loading() {
    dispatch({type: constants.app_messages.loading});
}

export function loaded() {
    dispatch({type: constants.app_messages.loaded});
}

export async function getData(code = false) {
    code = code ? code : getReportCode();
    let request = {};

    try {
        if (code === 'caso1.json' || code === 'caso2.json' || code === 'caso3.json') {
            request.data = require(`../../tests/mocks/jsons/${code}`);
        } else {
            request = await axios.get(`${envs["REACT_APP_URL_ENDPOINT"]}${code}`);
        }
        let data = {};
        if (request && request.data && request.data.data) {

            data = request.data.data;
        }
        dispatch({
            type: constants.app_messages.dataLoaded,
            data,
        });
        return data;
    } catch (e) {
        //Bad code
    }
}

