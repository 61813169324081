import React, {Component} from 'react';
import PropTypes from 'prop-types';
class Detail extends Component {
    render() {
        return (
            <div className="VehicleDataDetail clearfix padd-bt font-14">
                <div className="float-left txt-blue-light">{this.props.detailKey}</div>
                <div className="float-right txt-white font-bold">{this.props.detailValue}</div>
            </div>
        );
    }
}

Detail.propTypes = {
    detailKey:PropTypes.string,
    detailValue:PropTypes.oneOfType([PropTypes.string,PropTypes.number])
}

export default Detail;
