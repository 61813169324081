import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.sizes = {'xs': 'icon-xs', 'md': 'icon-md', 'lg': 'icon-lg'};
    }

    render() {
        return (
            <div
                className={`${this.props.bg} ${this.sizes[this.props.size]} rounded-circle d-inline-flex align-items-center justify-content-center ${classnames({'adjust-icon': this.props.adjust,'adjust-icon-reverse': this.props.adjustReverse})}`}>
                <FontAwesomeIcon
                    className={this.props.color}
                    icon={this.props.icon}
                    color={this.props.color}
                />
            </div>
        );
    }
}

Icon.defaultProps = {
    size: 'xs'
}
Icon.propTypes = {
    icon: PropTypes.object.isRequired,
    color: PropTypes.string,
    bg: PropTypes.string,
    size: PropTypes.oneOf([
        'xs', 'md', 'lg'
    ]),
    adjust: PropTypes.bool,
    adjustReverse: PropTypes.bool,
};


export default Icon;