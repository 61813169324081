export function share(type = 'facebook') {
    const share_url = window.location.href;
    const share_text="Nuevo informe autofact"
    if (type === "facebook") {
        popupWindow("https://www.facebook.com/sharer/sharer.php?u=" + share_url, 500, 310);
    }

    if (type === "twitter") {
        popupWindow("https://twitter.com/intent/tweet?source=webclient&amp;text=" + share_text + "&amp;url=" + share_url, 500, 310)
    }
    return false
}


function popupWindow(url, w, h) {
    var left = ( window.innerWidth / 2 ) - (w / 2)
    var top = ( window.innerHeight / 2 ) - (h / 2)
    return window.open(url, "Compartir", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)
}
