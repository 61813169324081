import constants from "../constants";
import {getReportCode} from '../../helpers/report';
import {getCountry} from '../../helpers/country';

const initialState = {
    loading: true,
    data: {},
    globalData: {}
};

export function getDatosVehiculo(data) {
    const datosvehiculo = data.datosvehiculo;
    if (datosvehiculo && datosvehiculo.header && datosvehiculo.body && Array.isArray(datosvehiculo.body.data)) {
        return {header: datosvehiculo.header, body: datosvehiculo.body.data[0]}
    }
    return false;
}

export function getGlobalData(data) {
    const globalData = data.globaldata;
    if (globalData) {
        return {reportCode: getReportCode(), ...globalData, countryData: getCountry(globalData.countryCode)}
    }
    return false;
}

export default function app(state = initialState, action) {
    if (action.type === constants.app_messages.loading)
        return {
            ...state,
            loading: true
        };
    if (action.type === constants.app_messages.loaded)
        return {
            ...state,
            loading: false
        };
    if (action.type === constants.app_messages.dataLoaded) {
        if (action.data) {
            const datosvehiculo = getDatosVehiculo(action.data);
            const globalData = getGlobalData(action.data, action.reportCode);
            delete action.data.globaldata;
            return {
                ...state,
                datosvehiculo,
                globalData,
                data: action.data,
            }
        }
    }
    return state;
}

