import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class LightButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showText: true,
            animateText: false,
            allowRotation: false,
            showShadows: false,
            touched:false
        }
        this.showText = this.showText.bind(this);
    }

    componentDidMount() {
       this.timer= setTimeout(() => {
            if (this.state && !this.state.touched) {
                this.showText();
            }
        }, 10000)
    }


    componentWillUnmount() {
        clearInterval(this.timer);
    }


    showText() {
        if (this.state.animateText) {
            this.setState({
                showText: true,
                allowRotation: true,
                showShadows: false,
                touched:true
            }, () =>
                setTimeout(() => {
                    this.setState({
                        animateText: false,
                        allowRotation: false
                    })
                }, 1000));
        }
        else {
            this.setState({
                animateText: true,
                allowRotation: true,
                touched:true
            }, () => {
                this.setState({
                    showText: false,
                    allowRotation: false,
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            showShadows: true
                        })
                    }, 1000);
                })
            });
        }

    }

    render() {
        return (
            <div
                className={`${classnames({
                    'd-flex': this.props.show,
                    'hide-text': !this.state.showText,
                    'shadows': this.state.showShadows
                })} d-none align-items-center animated lightSpeedIn btn-light-container`}>
                <div className={`${classnames({
                    'spin': this.state.allowRotation && this.state.showText,
                    'unspin': !this.state.showText
                })} btn-light d-inline-flex align-items-center justify-content-center `}
                     onClick={this.showText}>
                    <svg id="idea_2_" data-name="idea (2)" xmlns="http://www.w3.org/2000/svg" width="31.259" height="31.259" viewBox="0 0 31.259 31.259">
                        <g id="Group_129" data-name="Group 129">
                            <g id="Group_128" data-name="Group 128">
                                <path id="Path_34" data-name="Path 34" d="M151.621,143.52a7.275,7.275,0,0,0-6.354-6.338,7.616,7.616,0,0,0-.862-.049,7.256,7.256,0,0,0-5.1,12.406,5.715,5.715,0,0,1,1.762,4.06v.838h6.7V153.6a5.589,5.589,0,0,1,1.7-3.994,7.233,7.233,0,0,0,2.158-6.08h0Z" transform="translate(-128.786 -128.761)" fill="#fff"/>
                                <path id="Path_35" data-name="Path 35" d="M231.718,494.83a1.677,1.677,0,0,0,1.579-1.116h-3.158A1.677,1.677,0,0,0,231.718,494.83Z" transform="translate(-216.088 -463.571)" fill="#fff"/>
                                <path id="Path_36" data-name="Path 36" d="M210.286,440.532a.559.559,0,0,0,.558.558h4.466a.559.559,0,0,0,.558-.558v-1.675h-5.582Z" transform="translate(-197.447 -412.063)" fill="#fff"/>
                                <path id="Path_37" data-name="Path 37" d="M238.83,5.024a1.116,1.116,0,0,0,1.116-1.116V1.116a1.116,1.116,0,0,0-2.233,0V3.907A1.116,1.116,0,0,0,238.83,5.024Z" transform="translate(-223.201)" fill="#fff"/>
                                <path id="Path_38" data-name="Path 38" d="M66.3,67.879a1.143,1.143,0,0,0,1.579,0,1.116,1.116,0,0,0,0-1.579l-1.974-1.973a1.116,1.116,0,1,0-1.579,1.579Z" transform="translate(-60.093 -60.093)" fill="#fff"/>
                                <path id="Path_39" data-name="Path 39" d="M5.024,238.83a1.116,1.116,0,0,0-1.116-1.116H1.116a1.116,1.116,0,0,0,0,2.233H3.907A1.116,1.116,0,0,0,5.024,238.83Z" transform="translate(0 -223.201)" fill="#fff"/>
                                <path id="Path_40" data-name="Path 40" d="M67.381,379.116a1.116,1.116,0,0,0-1.079.291l-1.973,1.974a1.116,1.116,0,0,0,0,1.579,1.143,1.143,0,0,0,1.579,0l1.974-1.973a1.117,1.117,0,0,0-.5-1.87Z" transform="translate(-60.094 -355.934)" fill="#fff"/>
                                <path id="Path_41" data-name="Path 41" d="M380.986,379.407a1.116,1.116,0,1,0-1.579,1.579l1.974,1.973a1.143,1.143,0,0,0,1.579,0,1.116,1.116,0,0,0,0-1.579Z" transform="translate(-355.934 -355.935)" fill="#fff"/>
                                <path id="Path_42" data-name="Path 42" d="M433.621,237.714H430.83a1.116,1.116,0,0,0,0,2.233h2.791a1.116,1.116,0,0,0,0-2.233Z" transform="translate(-403.479 -223.201)" fill="#fff"/>
                                <path id="Path_43" data-name="Path 43" d="M381.007,67.88l1.973-1.974a1.116,1.116,0,0,0-1.579-1.579L379.428,66.3a1.116,1.116,0,0,0,0,1.579A1.143,1.143,0,0,0,381.007,67.88Z" transform="translate(-355.956 -60.093)" fill="#fff"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div
                    className={`${classnames({'hide': this.state.animateText})} d-flex mid-padd-l padd-r txt-ctn txt-blue-dark font-roboto font-bold font-14`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

LightButton.defaultProps = {}

LightButton.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.node.isRequired
};


export default LightButton;