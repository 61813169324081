import constants from "../constants";

const initialState = {
    survey: {
        jsonSchema: {}
    }
};


export default function survey(state = initialState, action) {
    if (action.type === constants.app_messages.loadSurvey) {
        return {
            ...state,
            survey: action.survey
        }
    }
    return state;
}
