import React from 'react';
import {connect} from "react-redux";

//components
import LightButton from '../../components/Buttons/LightButton';
import {faCheck, faExclamation, faLightbulb, faTimes} from '@fortawesome/free-solid-svg-icons';
import RoundedIcon from '../../components/Icons/RoundedIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import constants from '../../store/constants';

class TipsManager extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            showModal: false,
            tips: []
        }
        this.statusIcons = {
            ok: <RoundedIcon icon={faCheck} bg="bg-green" color="txt-white" size="md"/>,
            info: <FontAwesomeIcon icon={faLightbulb} className="txt-yellow icon-md"/>,
            warning: <RoundedIcon icon={faExclamation} bg="bg-yellow" color="txt-white" size="md"/>,
            error: <RoundedIcon icon={faTimes} bg="bg-red" color="txt-white" size="md"/>
        }
    }

    activeSectionHasTips(activeSection) {
        if (activeSection) {
            if (this.props.data && this.props.data[activeSection]) {
                const section = this.props.data[activeSection];
                if (section.footer && section.footer && Array.isArray(section.footer.tips) && section.footer.tips.length > 0) {
                    return section.footer.tips;
                }
            }
        }
        return false;
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    processTips(tip) {
        const styleRgex = /style=".*?"/gmi;
        return tip.replace(styleRgex,'');
    }

    showModalsTips(tips) {
        if (Array.isArray(tips)) {
            return tips.map((tip, index) => {
                return <div key={index}>
                    <div className="d-flex padd-b align-items-start">
                        <div
                            className="mid-padd-r">{tip.type && this.statusIcons[tip.type]}</div>
                        <div className="txt-gray-dakest font-13 line-1-3" style={{width: "100%", overflowX: 'scroll'}}
                             dangerouslySetInnerHTML={{__html: this.processTips(tip.message)}}/>
                    </div>
                </div>
            })
        }
        return false;
    }

    findActiveSection() {
        const sections = [];
        for (let section in this.props.activeSection) {
            if (constants.activeSections[section] && this.props.activeSection[section].active && this.props.activeSection[section].inView && this.activeSectionHasTips(section)) {
                const elDimension = document.querySelector(`#${section}`).getBoundingClientRect();
                //if (elDimension.bottom > 300) {
                sections.push({name: section, ...elDimension});
                //}
            }
        }
        if (sections.length === 1) {
            return sections[0].name;
        } else if (sections.length > 1) {
            return sections[sections.length - 1].name;
        }

        return false;
    }

    render() {
        const activeSection = this.findActiveSection();
        const tips = this.activeSectionHasTips(activeSection);
        return (
            <div className="TipsManager">
                {
                    Array.isArray(this.state.tips) && tips.length > 0 &&
                    <LightButton show>
                        <div className="txt-blue" onClick={this.toggleModal}>
                            Información importante sobre esta sección
                        </div>
                    </LightButton>
                }
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal} className='modal-bottom'>
                    <ModalHeader toggle={this.toggleModal}>
                        <div className="font-bold font-raleway ">
                            <div className="font-11 txt-gray-dark line-height-1-3" style={{paddingBottom: '2px'}}>
                                ASISTENTE INFORME AUTOFACT
                            </div>
                            <div className='txt-blue line-1-2 font-16 line-1-3'>
                                {this.props.data &&
                                this.props.data[activeSection]
                                && this.props.data[activeSection].header &&
                                this.props.data[activeSection].header.title}
                            </div>
                        </div>

                    </ModalHeader>
                    <ModalBody>
                        {this.showModalsTips(tips)}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default connect(store => ({
    data: store.app.data,
    activeSection: store.sections.activeSection
}))(TipsManager);