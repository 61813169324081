import React, {Suspense} from "react";
import {useSelector} from 'react-redux';
import {useState} from 'react';

//components
import CollapseTab from '../../components/Tabs/CollapseTab';
import StatusIcon from '../../components/Icons/StatusIcon';
import Loading from "../../components/Loading/Loading";

//assets
import icon1 from '../../assets/icons/sections/serv_informados.svg';


export default () => {

    const NotifiedServicesContainer = React.lazy(() => import('./components/NotifiedServicesContainer'));
    const [show, setShow] = useState(false);
    let boxData = useSelector(state => state.app.data);
    boxData = boxData && boxData.registrovehicular;

    const totalCards = (
        (boxData && boxData.body && boxData.body.data) ?
            (
                (boxData.body.data.highConfidence ? boxData.body.data.highConfidence.data.length : 0) +
                (boxData.body.data.lowConfidence ? boxData.body.data.lowConfidence.data.length : 0)
            ) : 0
    );
    const totalCarousels = (
        (boxData && boxData.body && boxData.body.data) ?
            (
                (boxData.body.data.highConfidence && boxData.body.data.highConfidence.data.length ? 1 : 0) +
                (boxData.body.data.lowConfidence && boxData.body.data.lowConfidence.data.length ? 1 : 0)
            ) : 0
    );


    return (
        <div onClick={() => setShow(!show)} className="NotifiedServicesSection" id="registrovehicular">
            {
                boxData && boxData.body && boxData.header && boxData.footer &&
                <CollapseTab
                    sectionName="registrovehicular"
                    carouselLength={totalCards}
                    totalCarousels={totalCarousels}
                    icon={icon1}
                    stateIcon={<StatusIcon
                        status={boxData.body && boxData.body.result && boxData.body.result.type}/>}
                    nav={<div
                        className="font-roboto font-bold font-14 txt-blue line-1-2">{boxData.header.title}</div>}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <Suspense fallback={<Loading size='medium'/>}>
                            {show && <NotifiedServicesContainer boxData={boxData}/>}
                        </Suspense>
                    </div>
                </CollapseTab>
            }
        </div>
    )
}
