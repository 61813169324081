import React, {Suspense} from "react";
import {useSelector} from 'react-redux';
import {useState} from 'react';

//components
import CollapseTab from '../../components/Tabs/CollapseTab';
import StatusIcon from '../../components/Icons/StatusIcon';
import Loading from "../../components/Loading/Loading";

//assets
import icon1 from '../../assets/icons/sections/rev_tec.svg';


export default () => {
    const TechnicalReviewContainer = React.lazy(() => import('./components/TechnicalReviewContainer'));
    const [show, setShow] = useState(false);
    let boxData = useSelector(state => state.app.data);
    boxData = boxData && boxData.revisiontecnica;

    return (
        <div onClick={() => setShow(!show)}  id="revisiontecnica" className="TechnicalReviewSection">
            {
                boxData && boxData.header && boxData.body && boxData.footer &&
                <CollapseTab
                    sectionName="revisiontecnica"
                    carouselLength={boxData.body && boxData.body.data && Array.isArray(boxData.body.data) ? boxData.body.data.length : 0}
                    icon={icon1}
                    stateIcon={<StatusIcon
                        status={boxData.body && boxData.body.result && boxData.body.result.type}/>}
                    nav={<div
                        className="font-roboto font-bold font-14 txt-blue line-1-2">{boxData.header.title}</div>}>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Suspense fallback={<Loading size='medium'/>}>
                            {show && <TechnicalReviewContainer boxData={boxData}/>}
                        </Suspense>
                    </div>
                </CollapseTab>
            }
        </div>
    )
}
