//reducers list
import app from "./reducers/app";
import sections from "./reducers/sections";
import survey from "./reducers/survey";
//midlewares
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";

let middleware = applyMiddleware(thunk);

const reducers = combineReducers({
    app, sections, survey
});

const store = createStore(reducers, middleware);

export default store;
