import constants from "../constants";

const initialState = {
    activeSection:{
        datosvehiculo:{active:true,inView:false}
    }
};


export default function sections(state = initialState, action) {
    if(action.type===constants.app_messages.sectionActivated){
        return {
            ...state,
            activeSection:{...state.activeSection,...action.activeSection}
        }
    }
    return state;
}
