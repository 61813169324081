import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shield from '../../assets/icons/patente-shield.svg'

class Patente extends React.Component {
    render() {
        return (
            <div className={`${classnames({mini:this.props.mini})} patente-signal min-padd-bt padd-lr d-inline-block margin-auto bg-white font-roboto text-uppercase font-bold`}>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="letter">{this.props.patente && this.props.patente.slice(0,2)}</div>
                    <div className="icon-1 mid-padd-lr"><div></div></div>
                    <div className="letter">{this.props.patente && this.props.patente.slice(2,4)}</div>
                    <div className="icon-2 mid-padd-lr"><img src={shield} alt="shield"/></div>
                    <div className="letter">{this.props.patente && this.props.patente.slice(4,8)}</div>
                </div>
                <div className="country-name d-flex align-items-center justify-content-center">
                    <div className="icon-3"></div>
                    <div className="mid-padd-r padd-l country">{this.props.country}</div>
                    <div className="icon-3"></div>
                </div>
            </div>
        );
    }
}

Patente.defaultProps = {
    country:'CHILE'
}

Patente.propTypes = {
    patente:PropTypes.string,
    country:PropTypes.string,
    mini:PropTypes.bool
};


export default Patente;