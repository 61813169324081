import constants from '../store/constants';
export function getCountry(code=''){
    code=code.toUpperCase();
    const countries=constants.countries;
    for(let i=0;i<countries.length;i++){
        if(countries[i].country_code === code){
            return countries[i];
        }
    }
    return {};
}