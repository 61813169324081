const WebFont = require('webfontloader');
export default () =>{
  setTimeout(()=>{
    WebFont.load({
      google: {
        families: ['Raleway:900', 'Roboto:400,700']
      }
    })
  },2500)
}

