import store from '../index';
import {SurveySrevice} from "../../services/SurveySrevice";
import constants from "../constants";
const dispatch = store.dispatch;

export async function getSurvey() {
    try {
        let survey = (await SurveySrevice.getSurvey(constants.survey.slug, constants.survey.country)).data;
        dispatch({
            type: constants.app_messages.loadSurvey,
            survey,
        });
        return survey;
    } catch (e) {
    }
}

export async function saveSurvey(surveyId, formData, userId) {
    try {
        let response = await SurveySrevice.saveSurvey(surveyId, formData, userId);
        return response;
    } catch (e) {}
}

export async function updateSuvey(surveyId, formData,userId) {
    try {
        let response = await SurveySrevice.updateSurvey(surveyId, formData, userId);
        return response;
    } catch (e) {}
}
export async function getSurveyByMail(surveyId, userId) {
    try {
        let survey = await SurveySrevice.getSuveyForUser(surveyId, userId)
        dispatch({
            type: constants.app_messages.surveyCompletely,
            survey,
        });
        return survey;
    } catch (e) {

    }

}
