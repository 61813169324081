import React, {Component} from 'react';
import {Provider} from "react-redux"
import store from './store'
//dispatchers
import {getData, loaded} from './store/dispatchers/app';
//containers
import Loading from './containers/AppLoading/index';
import Menu from './containers/AppMenu/Menu';
import Footer from './containers/AppFooter/Footer';
import TipsManager from './containers/AppTipsManager/TipsManager';
import VehicleData from './containers/VehicleData';
import OwnersRecords from './containers/OwnerRecords';
import ConsumptionEmission from './containers/ConsumptionEmission';
import VehicleSituation from './containers/VehicleSituation';
import TechnicalReview from './containers/TechnicalReview';
import VehiclesFleet from './containers/VehiclesFleet';
import CirculationPermissionsRecords from './containers/CirculationPermissionsRecords';
import TechnicalReviewRecords from './containers/TechnicalReviewRecords';
import CarAuction from './containers/CarAuction';
import OnCameraInfractions from './containers/OnCameraInfractions';
import NotifiedServices from './containers/NotifiedServices';
import AnotatedInfractions from './containers/AnnotatedInfractions';
import MOPInfractions from "./containers/MOPInfractions";
import PastPendingPaymentInfractions from "./containers/PastPendingPaymentInfractions";
import PaidInfractions from './containers/PaidInfractions';
import Documents from './containers/Documents';
import Recalls from './containers/Recalls';
import Survey from './containers/Survey';
//plugins
import lozad from './includes/lozad';
import webfontloader from './includes/webfontloader';
import {getSurvey} from "./store/dispatchers/survey";
//tagmanager
require("./includes/tagmanager");


class App extends Component {

    componentDidMount() {
        getData().then(() => {
            loaded();
            lozad();
            webfontloader();
            getSurvey();
        })
    }

    render() {

        return (
            <Provider store={store}>
                <Loading/>
                <div className="App">
                    <Menu/>
                    <VehicleData/>
                    <OwnersRecords/>
                    <VehicleSituation/>
                    <CirculationPermissionsRecords/>
                    <VehiclesFleet/>
                    <TechnicalReview/>
                    <TechnicalReviewRecords/>
                    <CarAuction/>
                    <NotifiedServices/>
                    <Recalls/>
                    <ConsumptionEmission/>
                    <AnotatedInfractions/>
                    <MOPInfractions/>
                    <PastPendingPaymentInfractions/>
                    <OnCameraInfractions/>
                    <PaidInfractions/>
                    <Survey/>
                    <Documents/>
                    <Footer/>
                    <TipsManager/>
                </div>
            </Provider>
        );
    }
}

export default App;
