import React, {PureComponent} from 'react';
import {connect} from "react-redux";

//components
import {Container, Row, Col} from 'reactstrap';
import IconText from '../../components/Icons/IconText';
import {faTwitter, faFacebook} from '@fortawesome/free-brands-svg-icons';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Patente from '../../components/Patente/Patente';
import Detail from './Detail';

//assets
import logo from '../../assets/images/logo_autofact.svg';
import email from '../../assets/icons/enviar_email.svg';
import pdfIco from '../../assets/icons/pdf_ico.svg';

//mocks
import classnames from 'classnames';
import {getCountry} from '../../helpers/country';
import {share} from '../../helpers/socialMedia';

//helpers
import constants from '../../store/constants';
const desktopUrl = require('../../config.json');

class Menu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            opened: false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.goToSection = this.goToSection.bind(this);
    }

    toggleMenu() {
        window.dataLayer = window.dataLayer || {};
        window.dataLayer.push({
            event: 'callback',
            type: !this.state.opened ? 'MOBILEREPORT_BEHAVIOR_MENU_OPEN' : 'MOBILEREPORT_BEHAVIOR_MENU_CLOSE'
        });
        this.setState({
            opened: !this.state.opened
        });
    }

    goToSection(section) {
        window.dataLayer = window.dataLayer || {};
        window.dataLayer.push({
            event: 'callback',
            type: 'MOBILEREPORT_BEHAVIOR_MENU_CTA_ITEM',
            data: {
                sectionName: section
            }
        });
        const el = document.getElementById(section);
        if (el) {
            this.toggleMenu();
            window.scrollTo({
                top: el ? el.offsetTop - 88 : 0,
                behavior: 'smooth'
            });
            const sectionIcon = el.querySelector('header');
            if (sectionIcon && !sectionIcon.classList.contains('opened')) {
                sectionIcon.click();
            }
        }
    }

    getMenuItems() {
        let menuItems = [], index = 0;
        for (let section in constants.activeSections) {
            if(this.props.data[section] && this.props.data[section].header){
                menuItems.push(
                    <div key={index} onClick={() => this.goToSection(section)}>
                        <Detail>{this.props.data[section].header.title}</Detail>
                    </div>
                );
            }
            index += 1;
        }
        return menuItems;
    }

    render() {
        return (
            <div className="Menu-Container">
                <div className="Menu">
                    <a href={this.props.globalData && getCountry(this.props.globalData.countryCode).url}>
                        <img src={logo} alt="logo" className="d-inline-block logo"/>
                    </a>
                    <div className="title d-inline-block font-roboto font-20 txt-blue-light">
                        <strong>Informe Full</strong> <span
                        className="txt-yellow font-15 font-raleway font-black">BETA</span>
                    </div>
                    <div className="menu-icon float-right" onClick={this.toggleMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`MenuOptions  justify-content-end ${classnames({'d-none': !this.state.opened})}`}
                         onClick={() => this.toggleMenu()}>
                        <div onClick={(e) => e.stopPropagation()}
                             className={`bg-gray RightSide bg-blue slideInRight ${classnames({'animated fast': this.state.opened})}`}>
                            <div className="MenuItemsContainer">
                                <div className="bg-gray-dark padd d-block no-undeline"
                                     style={{borderBottom: "1px solid #DCE7F0"}}>
                                    <IconText>
                                        <div className="d-inline-block">
                                            <img data-src={email} alt="pdf" className="lozad img-fluid wide"
                                                 style={{width: "20px"}}/>
                                        </div>
                                        <div className="txt-gray">Enviar informe a mi correo</div>
                                    </IconText>
                                </div>
                                <Container fluid className="bg-gray-dark">
                                    <Row noGutters className="padd-bt social-container">
                                        <div className="separator"></div>
                                        <Col>
                                            <a href={this.props.globalData && this.props.globalData.pdfUrl}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               className="no-undeline"
                                            >
                                                <IconText>
                                                    <div className="d-inline-block">
                                                        <img data-src={pdfIco} alt="pdf"
                                                             className="lozad img-fluid wide"
                                                             style={{width: "17px"}}/>
                                                    </div>
                                                    <div className="txt-gray">Versión PDF</div>
                                                </IconText>
                                            </a>
                                        </Col>
                                        <Col className="text-right d-flex align-items-center justify-content-end">
                                            <IconText>
                                                <div className="txt-gray">Compartir</div>
                                                <FontAwesomeIcon onClick={() => share("twitter")}
                                                                 className="txt-gray"
                                                                 icon={faTwitter}
                                                                 size="lg"
                                                />
                                            </IconText>
                                            <IconText>
                                                <div className="min-padd-r"></div>
                                                <FontAwesomeIcon onClick={() => share("facebook")}
                                                                 className="txt-gray"
                                                                 icon={faFacebook}
                                                                 size="lg"
                                                />
                                            </IconText>
                                        </Col>
                                    </Row>
                                </Container>
                                <div className="bg-gray-dark-1 text-center padd">
                                    {
                                        this.props.globalData &&
                                        <Patente patente={this.props.globalData.plate}
                                                 country={this.props.globalData.countryData && this.props.globalData.countryData.upper}
                                                 mini
                                        />
                                    }
                                </div>
                                <div className="med-padd-b">
                                    {this.getMenuItems()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="GoToDesktop"
                   href={this.props.globalData ? desktopUrl.REACT_APP_DESKTOP_REPORT_URL + 'informe/ver/' + this.props.globalData.reportCode : ''}>
                    <div className="padd-lr mid-padd-tb txt-yellow font-raleway font-12 bg-blue-dark">
                        <FontAwesomeIcon
                            className="txt-yellow"
                            icon={faChevronLeft}
                            size="1x"
                        />
                        <div className="d-inline-block min-padd-l">VOLVER AL INFORME TRADICIONAL</div>
                    </div>
                </a>
            </div>
        );
    }
}

export default connect(store => ({
    globalData: store.app.globalData,
    data: store.app.data,
}))(Menu);
