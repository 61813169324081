import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

class Loading extends Component {
    render() {
        const loading = document.getElementById('loading-marker');
        if(loading && loading.classList){
            loading.classList.add('d-none');
        }
        return (
            <div></div>
        );
    }
}

Loading.propTypes = {
    loading: PropTypes.bool
};

export default connect(store => ({
    loading: store.app.loading
}))(Loading);
