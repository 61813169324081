export default {
    app_messages: {
        loading: 'LOADING_APP',
        loaded: 'APP_FULLY_LOADED',
        dataLoaded: 'DATA_LOADED',
        sectionActivated: 'SECTION_ACTIVATED',
        loadSurvey:'LOAD_SURVEY',
        surveyCompletely:'SURVEY_COMPLETELY',

    },
    survey:{
        slug:"survey-mobile-rate",
        country:"CL"
    },
    countries: [
        {
            country_code: 'AR',
            name: 'Argentina',
            lower: 'argentina',
            upper: 'ARGENTINA',
            url: 'https://www.autofact.com.ar'
        },
        {country_code: 'CL', name: 'Chile', lower: 'chile', upper: 'CHILE', url: 'https://www.autofact.cl'},
        {
            country_code: 'CO',
            name: 'Colombia',
            lower: 'colombia',
            upper: 'COLOMBIA',
            url: 'https://www.autofact.com.co'
        },
        {
            country_code: 'CR',
            name: 'Costa Rica',
            lower: 'costa rica',
            upper: 'COSTA RICA',
            url: 'https://www.autofact.com.cr'
        },
        {country_code: 'MX', name: 'México', lower: 'mexico', upper: 'MEXICO', url: 'https://www.autofact.com.mx'},
        {country_code: 'PE', name: 'Perú', lower: 'peru', upper: 'PERU', url: 'https://www.autofact.pe'}
    ],
    activeSections: {
        'datosvehiculo':true,
        'historialpropietarios':true,
        'situacionvehiculo':true,
        'permisocirculacion':true,
        'flotavehiculos':true,
        'revisiontecnica':true,
        'historialrevisiontecnica':true,
        'remates':true,
        'registrovehicular':true,
        'recalls':true,
        'consumoemisiones':true,
        'proyeccionmultas':true,
        'multasregistrocivil':true,
        'multasautopistasmunicipalidades':true,
        'multastag':true,
        'multasviasexclusivas':true,
        'multasresueltas':true,
    }
}
