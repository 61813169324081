import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

//components
import RoundedIcon from './RoundedIcon';
import {faCheck, faTimes, faExclamation,faCog,faDesktop} from '@fortawesome/free-solid-svg-icons';

class StatusIcon extends PureComponent {
    render() {
        let icon = '';
        if (this.props.status === 'success') {
           icon= <RoundedIcon icon={faCheck} bg="bg-green" color="txt-white" size="md" className="section-status-success"/>
        }else
        if (this.props.status === 'warning') {
            icon= <RoundedIcon icon={faExclamation} bg="bg-yellow" color="txt-white" size="md" className="section-status-warning"/>
        }else
        if (this.props.status === 'danger') {
            icon= <RoundedIcon icon={faTimes} bg="bg-red" color="txt-white" size="md" className="section-status-danger"/>
        }
        else
        if (this.props.status === 'caution') {
            icon= <RoundedIcon icon={faExclamation} bg="bg-yellow" color="txt-white" size="md" className="section-status-caution"/>
        }
        else
        if (this.props.status === 'desktop') {
            icon= <RoundedIcon icon={faDesktop} color="txt-blue" size="lg" className="section-status-not-implemented" adjustReverse/>
        }
        else{
            icon= <RoundedIcon icon={faCog} bg="bg-blue" color="txt-white" size="md" className="section-status-none"/>
        }
        return icon;
    }
}

StatusIcon.defaultProps = {
    status: 'success'
}
StatusIcon.propTypes = {
    status: PropTypes.oneOf(['success','warning','danger','caution','desktop',null])
};


export default StatusIcon;