import React from 'react';
import PropTypes from 'prop-types';

//components
import {Collapse} from 'reactstrap';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Waypoint} from "react-waypoint";

//helpers
import classnames from 'classnames';

//actions
import {activeSection} from '../../store/dispatchers/sections';

class CollapseTab extends React.Component {
  constructor(props) {
    super(props);
    this.onEntered = this.onEntered.bind(this);
    this.onExited = this.onExited.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      status: 'closed',
      inView: false
    };
    this.justOpened = false;
    this.justClosed = false;
  }

  onEntered() {
    window.dataLayer = window.dataLayer || {};
    window.dataLayer.push({
      event: 'callback',
      type: 'MOBILEREPORT_BEHAVIOR_TAB_OPEN',
      data: {
        sectionName: this.props.sectionName,
        totalItems: this.props.carouselLength,
        totalRows: this.props.totalCarousels || (this.props.carouselLength >= 1 ? 1 : 0)
      }
    });

    this.justOpened = true;
    setTimeout(() => {
      this.justOpened = false;
    }, 1000);

    this.setState({status: 'opened'});
    activeSection({[this.props.sectionName]: {active: this.state.collapse, inView: this.state.inView}});
  }

  onExited() {
    window.dataLayer = window.dataLayer || {};
    window.dataLayer.push({
      event: 'callback',
      type: 'MOBILEREPORT_BEHAVIOR_TAB_CLOSE',
      data: {
        sectionName: this.props.sectionName
      }
    });
    this.justClosed = true;
    setTimeout(() => {
      this.justClosed = false;
    }, 1000);

    this.setState({status: 'closed'});
    activeSection({[this.props.sectionName]: {active: this.state.collapse, inView: this.state.inView}});
  }

  toggle() {
    this.setState({collapse: !this.state.collapse}, () => {
      if (!this.state.collapse) {
        activeSection({[this.props.sectionName]: {active: true, inView: true}});
      }
    });

  }

  activeSections(inView) {
    setTimeout(() => {
      if (!this.justOpened && !this.justClosed) {
        window.dataLayer = window.dataLayer || {};
        window.dataLayer.push({
          event: 'callback',
          type: inView ? 'MOBILEREPORT_BEHAVIOR_TAB_ON_VIEWPORT' : 'MOBILEREPORT_BEHAVIOR_TAB_OFF_VIEWPORT',
          data: {
            sectionName: this.props.sectionName
          }
        });
      }
    }, 500);
    this.setState({inView});
    activeSection({[this.props.sectionName]: {active: this.state.collapse, inView}});
  }

  render() {
    return (
        <div className="v-tabs">
          <header className={ `${ classnames({opened: this.state.collapse}) } container-fluid ` }
                  onClick={ this.toggle }>
            <div className="row">
              {
                  this.props.icon &&
                  <div className="h-icon d-inline-block padd-l d-flex align-items-center">
                    <div>
                      <img data-src={ this.props.icon } alt="icon" className="img-fluid line-1 lozad"/>
                    </div>
                  </div>
              }
              {
                  this.props.stateIcon &&
                  <div className="h-icon-1 d-inline-block  d-flex align-items-center">
                      <div>
                          { this.props.stateIcon }
                      </div>
                  </div>
              }
              <div className="col d-flex align-items-center header-marker">
                { this.props.nav }
              </div>
              <div className="d-inline-flex pointer txt-yellow padd-r padd-l align-items-center icon-marker">
                { this.state.collapse ? <div className={ `IndicatorIconMinus ${ this.props.sectionName }` }>
                  <FontAwesomeIcon icon={ faMinus } size="sm"></FontAwesomeIcon>
                </div> : <div className="IndicatorIconPlus">
                  <FontAwesomeIcon icon={ faPlus } size="sm"></FontAwesomeIcon>
                </div> }

              </div>
            </div>
          </header>
          <Waypoint
              onEnter={ () => {
                this.activeSections(true)
              } }
              onLeave={ () => {
                this.activeSections(false)
              } }
              bottomOffset={ 60 }
              topOffset={ 250 }
          >
            <Collapse
                isOpen={ this.props.alwaysOpened || this.state.collapse }
                onEntered={ this.onEntered }
                onExited={ this.onExited }
                style={ {trasnsition: "none !important"} }
            >
              { this.props.children }
            </Collapse>
          </Waypoint>
        </div>
    );
  }
}

CollapseTab.propTypes = {
  icon: PropTypes.any,
  stateIcon: PropTypes.any,
  nav: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
  alwaysOpened: PropTypes.bool,
};


export default CollapseTab;
