import Rating from './Ratting'
import Sucessful from './Successful'
import {Button, Input} from 'reactstrap';
import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {saveSurvey,updateSuvey} from "../../../store/dispatchers/survey";

function getVerticalScrollPercentage() {
    const elm = document.body;
    let p = document.body.parentNode,
        pos = (elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight) * 100;
    return pos;
}

const surveyStep = {
    'recomenUsStep': 0,
    'easyToFind': 1,
    'thanks': 2,
    'somethingToAdd': 3,
    'thanksAndFinish': 4
}

export default (props) => {
    const {schema} = props;
    const {uiSchema} = props;
    const {properties} = schema;
    const fields = uiSchema["ui:order"].map(item => [item, properties[item]]) || [];
    let [currentStep, setStep] = useState(0);
    const [nextButtonVisible, setNextButtonVisible] = useState(false);
    const show = (sessionStorage.getItem('showed') ? false : true);
    const [visible, setVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const data = useSelector((state) => state.app.globalData);
    const [postSurveyAnswer, setPostSurveyAnswer] = useState({
        formData: {},
        surveyId: '',
        userId: '',
        metadata: {},
    });

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const percent = parseInt(getVerticalScrollPercentage());
            setVisible(percent > 90 && show);
        })
    });

    const nextStep = async (astep) => {
        if ( astep === 1) {
            const response = await saveSurvey(schema.id, postSurveyAnswer.formData, data.userEmail);
            postSurveyAnswer.surveyId = response.data.data.id
            setPostSurveyAnswer(postSurveyAnswer);
        }
        if ( astep === 3) {
            await updateSuvey(postSurveyAnswer.surveyId , postSurveyAnswer.formData, data.userEmail)
        }
        (astep === 0) && setProgress(50);
        (astep > 0) && setProgress(100);
        setStep(astep + 1);
        setNextButtonVisible(false);
    }

    const onRate = (rate, key) => {
        postSurveyAnswer.formData[key] = rate
        setPostSurveyAnswer(postSurveyAnswer);
        setNextButtonVisible(true);

    }

    const onClose = () => {
        sessionStorage.setItem("showed", "false");
        setVisible(false);
    }
    return (
        <React.Fragment>
            {visible && <div className={'wrap'}>
                <div className={'progess'}>
                    {progress >= 50 && <div className={'first-step'}></div>}
                    {progress === 100 && <div className={'second-step'}></div>}
                </div>
                <fieldset>
                    <div className={'wizzard-survey'}>
                        {(currentStep === surveyStep.recomenUsStep || currentStep === surveyStep.thanks || currentStep === surveyStep.thanksAndFinish) &&
                        <i className="close-icon fa fa-times-circle-o" aria-hidden="true"
                           onClick={onClose}></i>}
                        {fields.map(([key, value], step) =>
                            currentStep === step && <div className={'form-group field'} key={step}>
                                {

                                    (currentStep !== surveyStep.thanks && currentStep !== surveyStep.thanksAndFinish) ?
                                        <React.Fragment>
                                            <div className="blue-line"></div>

                                            <label className="control-label" htmlFor="root">{value.title}</label>
                                            {
                                                currentStep < surveyStep.thanks &&
                                                <Rating onRate={(rate) => onRate(rate, key)}/>
                                            }
                                            {
                                                currentStep === surveyStep.somethingToAdd &&
                                                <Input type="textarea" name="text" id="exampleText" className={'note'}
                                                       onChange={(event) => onRate(event.target.value, 'comments')}/>
                                            }
                                            {(nextButtonVisible || currentStep > surveyStep.thanks) &&
                                            <Button
                                                onClick={() => nextStep(currentStep)}>{(currentStep === surveyStep.recomenUsStep) ? "Siguiente" : "Enviar"}</Button>}
                                        </React.Fragment> :
                                        <Sucessful nextStep={() => nextStep(currentStep)}
                                                   showNext={(currentStep < surveyStep.thanksAndFinish)}/>
                                }
                            </div>
                        )}
                    </div>
                </fieldset>
            </div>}
        </React.Fragment>
    )
}
