import React, {useState} from 'react';
export default (props) => {
    const [rating, setRating] = useState("");
    var stars = [];

    const rate = (rating) => {
        setRating(rating);
        props.onRate(rating)
    }
    for (var i = 1; i <= 5; i++) {
        var klass = 'star-rating__star';

        if (rating >= i && rating != null) {
            klass += ' is-selected';
        }
        stars.push(
            <label
                className={klass}
                onClick={rate.bind(this, i)}
            >
                <span className="fa fa-star"></span>
            </label>
        );
    }
    return (
        <div className={`star-rating`}>
            {stars}
        </div>
    );
}
