import React, {useEffect, useState} from 'react';
import Wizard from './components/Wizzard';
import Form from "@rjsf/core";
import {useSelector} from "react-redux";
import {getSurveyByMail} from "../../store/dispatchers/survey";

const stateForCheckSurveyCompletely = {
    notLoaded: 0,
    loadQuery: 1,
    notCompletely: 2

}
const fields = {geo: Wizard};
export default () => {
    let survey = useSelector(state => state.survey.survey) || {};
    const schema = Object.assign(survey?.jsonSchema || {}, {id: survey?.id});
    const data = useSelector((state) => state.app.globalData);
    const [surveyCompletely, setSurveyCompletely] = useState(stateForCheckSurveyCompletely.notLoaded);

    useEffect(() => {
        (async () => {
            if (schema.id && surveyCompletely === stateForCheckSurveyCompletely.notLoaded) {
                const response = await getSurveyByMail(schema.id, data.userEmail);
                setSurveyCompletely(response.data.length > 0 ? stateForCheckSurveyCompletely.loadQuery : stateForCheckSurveyCompletely.notCompletely);
            }
        })()
    });

    return (
        <React.Fragment>
            {surveyCompletely === stateForCheckSurveyCompletely.notCompletely && <Form schema={schema}
                                                                                      uiSchema={survey.uiSchema}
                                                                                      fields={fields}
            />

            }
        </React.Fragment>

    )
}



