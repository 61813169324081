import React from 'react';

export default (props) => {
    return (
        <React.Fragment>
            <div className={'successful'}>
                <div className="icon">
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                </div>
                <div className={'body'}>
                    <h3>¡Gracias!</h3>
                    <h5>Con tu opinión mejoramos juntos</h5>
                </div>
            </div>
            {props.showNext &&
            <div className={'body-text'}>¿Tienes algún otro comentario? <span onClick={props.nextStep}>Hazlo aquí</span>
            </div>}
        </React.Fragment>
    )
}
