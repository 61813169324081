import React from 'react';
import PropTypes from 'prop-types';

class IconText extends React.Component {
    render() {
        return (
            <div className="d-inline-flex align-items-center">
                <div className="mid-padd-r">{this.props.children[0]}</div>
                {this.props.children[1]}
            </div>
        );
    }
}

IconText.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired
};


export default IconText;