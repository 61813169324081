import React, {PureComponent} from 'react';
import {connect} from "react-redux";

//components
import {Container, Row, Col} from 'reactstrap';
import IconText from '../../components/Icons/IconText';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {faTwitter, faFacebook} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

//assets
import pdfIco from '../../assets/icons/pdf_ico.svg';
import logo from '../../assets/images/logo_autofact_blanco.svg';

//mocks
import {share} from '../../helpers/socialMedia';
import {getCountry} from '../../helpers/country';

class Footer extends PureComponent {
    render() {
        return (
            <Container fluid className="Footer">
                <Row>
                    <Col xs={12} className="med-padd-bt bg-blue-dark txt-blue-light">
                        <IconText>
                            <FontAwesomeIcon
                                className="txt-blue-light"
                                icon={faQuestionCircle}
                            />
                            <div className="font-13 txt-blue-light"><u>¿Cómo interpretar esta información?</u></div>
                        </IconText>
                        <div className="padd-bt">
                            <IconText>
                                <FontAwesomeIcon
                                    className="txt-blue-light"
                                    icon={faQuestionCircle}
                                />
                                <div className="font-13 txt-blue-light"><u>Glosario</u></div>
                            </IconText>
                        </div>
                        <div className='font-12 txt-blue-light-1'>
                            <p className="padd-b mid-padd-t">
                                i) El informe AUTOFACT está basado en información provista a AUTOFACT que estuvo
                                disponible
                                el {this.props.globalData && this.props.globalData.generationTimestamp}. Sin embargo, puede existir información que NO haya sido
                                reportada a AUTOFACT o que haya sido recientemente entregada pero no incluida aún en las
                                bases de datos. Por ende, podrían existir datos que NO están presentes en este informe,
                                incluyendo accidentes, multas, registros de kilometrajes, remates, pertenencia a flotas
                                u
                                otros.
                            </p>
                            <p>
                                ii) Utiliza los antecedentes que te entrega el informe AUTOFACT como una ayuda para
                                conocer mejor el vehículo que quieres comprar, te ayudará a reducir el riesgo y poder
                                pagar
                                un precio más justo. Como complemento, realiza una inspección visual, mecánica, prueba
                                del
                                vehículo y otras actividades para reducir el riesgo aún más.
                            </p>
                            <p className="padd-t">
                                iii) El informe AUTOFACT
                                contiene información en línea e información que proviene de bases de datos históricas y
                                otras fuentes externas. Por lo que no puede garantizar ni certificar la información
                                presente
                                en este informe. Lee los mensajes en cada sección para que puedas comprender la
                                actualización de cada información.</p>
                        </div>
                    </Col>
                    <Col xs={12} className="bg-gray-dark font-14 txt-gray">
                        <Row noGutters className="padd-bt">
                            <Col>
                                <a href={this.props.globalData && this.props.globalData.pdfUrl}
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <IconText>
                                        <div style={{width: "20px"}}>
                                            <img src={pdfIco} alt="pdf" className="img-fluid wide logo"/>
                                        </div>
                                        <div className="txt-gray">Versión PDF</div>
                                    </IconText>
                                </a>
                            </Col>
                            <Col className="text-right d-flex align-items-center justify-content-end">
                                <IconText>
                                    <div className="min-padd-r">Compartir</div>
                                    <FontAwesomeIcon onClick={() => share("twitter")}
                                                     className="txt-gray"
                                                     icon={faTwitter}
                                                     size="lg"
                                    />
                                </IconText>
                                <IconText>
                                    <div className="min-padd-r"></div>
                                    <FontAwesomeIcon onClick={() => share("facebook")}
                                                     className="txt-gray"
                                                     icon={faFacebook}
                                                     size="lg"
                                    />
                                </IconText>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="bg-blue med-padd text-center">
                        <a href={this.props.globalData && getCountry(this.props.globalData.countryCode).url}>
                            <img src={logo} alt="logo" className="img-fluid" style={{width: "120px"}}/>
                        </a>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default connect(store => ({
    globalData: store.app.globalData,
}))(Footer);