import React, {PureComponent} from 'react';
import {connect} from "react-redux";

//Components
import Patente from '../../components/Patente/Patente';
import carImage from '../../assets/images/top-celeste.jpg';
import carImageSubstitute from '../../assets/images/top-celeste-substitute.jpg';
import Detail from './components/Detail';

//Helpers
import {Waypoint} from "react-waypoint";

//actions
import {activeSection} from '../../store/dispatchers/sections';

class VehicleData extends PureComponent {

    constructor(props) {
        super(props);
        this.getDetails = this.getDetails.bind(this);
    }

    getDetails() {
        const body = this.props.datosvehiculo.body;
        let detailItems = [];
        if (body) {
            for (let key in body) {
                detailItems.push(<Detail key={key} detailKey={body[key].key} detailValue={body[key].value}/>);
            }
        }
        return detailItems;
    }

    activeSections(inView) {
        activeSection({'datosvehiculo': {active: true, inView}});
    }

    render() {
        return (
            <Waypoint
                onEnter={() => {
                    this.activeSections(true)
                }}
                onLeave={() => {
                    this.activeSections(false)
                }}
                topOffset={250}
            >
                <div className="VehicleDataSection" id="datosvehiculo">
                    <img src={carImageSubstitute} data-src={carImage} alt="car" className="lozad img-fluid wide"/>
                    {
                        this.props.datosvehiculo &&
                        <div className="bg-image lozad bg-blue-dark" data-background-image="/top-bg.jpg">
                            <div className="patente-container text-center">
                                <Patente patente={this.props.globalData.plate}
                                         country={this.props.globalData.countryData.upper}></Patente>
                            </div>
                            <div className="txt-blue-light text-center font-13 padd">Código
                                Informe: {this.props.globalData && this.props.globalData.reportCode}</div>
                            <div
                                className="txt-yellow font-raleway font-black font-16 padd-lr padd-t text-uppercase">
                                {this.props.datosvehiculo.header && this.props.datosvehiculo.header.title}
                            </div>
                            <div className="padd-lr">
                                {this.getDetails()}
                            </div>
                            <div
                                className="txt-yellow font-raleway font-black font-16 padd-lr med-padd-t padd-b text-uppercase">
                                RESUMEN DEL VEHÍCULO
                            </div>
                        </div>
                    }
                </div>
            </Waypoint>
        );
    }
}

VehicleData.propTypes = {}

export default connect(store => ({
    datosvehiculo: store.app.datosvehiculo,
    globalData: store.app.globalData,
}))(VehicleData);
