export  function loadScript(src, callback) {
    var s,
        r,
        t;
    r = false;
    s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = src;
    s.onload = s.onreadystatechange = function () {
        //console.log( this.readyState ); //uncomment this line to see which ready states are called.
        if (!r && (!this.readyState || this.readyState === 'complete')) {
            r = true;
            callback();
        }
    };
    t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
}

export function configSentry(env) {
    let errors = [];
    window.onerror = function (msg) {
        if (errors) {
            errors.push({
                msg: msg
            });
            /*loadScript('https://browser.sentry-cdn.com/5.1.1/bundle.min.js', function () {
                window.Sentry.init({
                    dsn: env.REACT_APP_SENTRY_DSN,
                    environment: env.REACT_APP_ENVIRONMENT
                });


                if (errors.length > 0) {
                    errors.forEach(function (item) {
                        window.Sentry.captureMessage(item.msg);
                    })
                    errors = false;
                }
            })*/
        }
    }
}