import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
class Detail extends PureComponent {
    render() {
        return (
            <div className="MenuDataDetail padd font-15 font-bold txt-blue d-flex">
                <div className="point"><div></div></div>
                <div>{this.props.children}</div>
            </div>
        );
    }
}

Detail.propTypes = {
    children:PropTypes.string
}

export default Detail;
