import store from '../index';
import constants from '../constants';
const dispatch = store.dispatch;


export function activeSection(section) {
    dispatch({
        type: constants.app_messages.sectionActivated,
        activeSection: section
    });
}