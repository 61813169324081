import axios from "axios";

const envs = require('../config');

export class SurveySrevice {

    static async getSurvey(slug, country) {
        let data = {
            slug: slug,
            countryCode: country,
        }
        const request = await axios.get(`${envs.REACT_APP_SURVEY_URL_ENDPOINT}survey`, {params: data});
        return request.data;
    }

    static async getSuveyForUser(surveyId, emailUserInforme) {
        let queryStringSurvey = {
            where: {
                survey_id: surveyId,
                user_email: emailUserInforme,
            },
        };
        const request = await axios.get(`${envs.REACT_APP_SURVEY_URL_ENDPOINT}survey-answer`, {params: {query: queryStringSurvey}});
        return request.data;
    }

    static async saveSurvey(surveyId, formData, userId) {
        let data = {
            formData: formData,
            surveyId: surveyId,
            userId: userId,
            metadata: {},
        };

        const request = await axios.post(`${envs.REACT_APP_SURVEY_URL_ENDPOINT}survey-answer`, data);
        return request;
    }

    static async updateSurvey(surveyId, formData, userId) {
        let data = {
            answer: formData,
        };
        const uri = 'survey-answer?query={"where":{"id":' + String(surveyId) + '}}';
        const urlPutSurveyAnswer = encodeURI(uri);
        const request = await axios.put(`${envs.REACT_APP_SURVEY_URL_ENDPOINT+urlPutSurveyAnswer}`, data);
        return request;
    }


}
