import PropTypes from 'prop-types';
import React from 'react'

export default function Loading(props) {
    return (
        <div id='loading' className={props.size}>
            <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66"
                 xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33"
                        r="30"></circle>
            </svg>
        </div>
    );
}
Loading.defaultProps = {
    size: 'medium'
}
Loading.propTypes = {
    size: PropTypes.oneOf(['big','medium','small'])
}